import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "../components/image";
import { useStaticQuery, graphql } from "gatsby";

const DocumentsPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      files: allFile(
        filter: { extension: { eq: "pdf" } }
        sort: { order: ASC, fields: [name] }
      ) {
        edges {
          node {
            publicURL
            name
            extension
            prettySize
          }
        }
      }
    }
  `);
  return (
    <Layout location={location}>
      <div>
        <SEO title="Downloads" />
        <article>
          <h1>Dokumente</h1>
          <ul className="documents-list">
            {data.files.edges.map((file, index) => {
              const iconName = file.node.extension + ".png";

              const prettyFileName = file.node.name
                .replace(/Stand_\d*/g, "") // Removes e.g. "Stand_20191129" from displayed filename
                .replace(/_/g, " ") // Replace underscores with spaces for displayed filename
                .trim();

              const uglyDateFromFileName = file.node.name.replace(
                /^([a-zA-ZäöüßÄÖÜ]|_)+/,
                ""
              );

              let dateFromFile;
              if (uglyDateFromFileName.match(/\d{8}/)) {
                dateFromFile =
                  uglyDateFromFileName.substring(6, 8) +
                  "." +
                  uglyDateFromFileName.substring(4, 6) +
                  "." +
                  uglyDateFromFileName.substring(0, 4);
              }

              return (
                <li key={`pdf-${index}`}>
                  <div
                    style={{
                      display: "inline-grid",
                      gridTemplateColumns: "auto auto",
                    }}
                  >
                    <Image
                      className="icon"
                      filename={iconName}
                      alt={file.node.extension}
                    />
                    <div>
                      <a href={file.node.publicURL} download={file.node.name}>
                        {prettyFileName}
                      </a>{" "}
                      (Größe: {file.node.prettySize})
                    </div>
                  </div>
                  {dateFromFile ? (
                    <p>Zuletzt geändert am {dateFromFile}</p>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </article>
      </div>
    </Layout>
  );
};

export default DocumentsPage;
